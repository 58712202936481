import React, { useContext } from 'react'
import { GlobalDataContext } from "../../context/context";
import CountUp from 'react-countup';



const CounterUp = ({ image }) => {

    const { rpdata } = useContext(GlobalDataContext);


    const counterInfo = [
        {
            title: rpdata?.dbPrincipal?.exprYears  ? 'Years Of Experience' : 'Best Service',
            number: rpdata?.dbPrincipal?.exprYears ? rpdata?.dbPrincipal?.exprYears : '100',
            simbolo: rpdata?.dbPrincipal?.exprYears ? '+' : '%',
        },
        {
            title: 'Professionals',
            number: 100,
            simbolo: '%',
        },
        {
            title: rpdata?.dbPrincipal?.miles ? `Miles Around ${rpdata?.dbPrincipal?.location[0]?.address}` : 'Cost-Effectiveness',
            number: rpdata?.dbPrincipal?.miles ? rpdata?.dbPrincipal?.miles : '100',
            simbolo: rpdata?.dbPrincipal?.miles ? '+' : '%',
        },
        {
            title: 'On Time',
            number: 100,
            simbolo: '%',
        },
    ]

    return (
        <div className='bgCountent' style={{ backgroundImage: `url("${image}")` }}>
            <img
                src={"https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/image%20(5).png?alt=media&token=c9af34df-44bf-4595-909a-86f0c9ab5d30"}
                alt='no found'
                loading='lazy'
                className='absolute top-0 left-0 w-[100%] h-[6%]'
            />

            <img
                src={"https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/image%20(6).png?alt=media&token=89428890-d8bb-4227-8838-dbffb470c309"}
                alt='no found'
                loading='lazy'
                className='absolute bottom-0 left-0 w-[100%] h-[6%]'
            />
            <h2 className='text-center py-4 relative text-white px-5 md:px-[20%]'>{rpdata?.dbSlogan?.[4].slogan}</h2>
            <div className='w-4/5 mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4'>
                {
                    counterInfo.map((items, index) => {
                        const colorContent = index % 2 === 0 ? 'bg1' : 'bgplata'
                        return (
                            <div key={index} className={`text-center p-3 relative ${colorContent}`}>
                                <div>
                                    <CountUp
                                        end={items.number}
                                        duration={5}
                                        className='text-white text-[69px]'
                                    />
                                    <span className='text-white text-[69px]'>{items.simbolo}</span>
                                </div>
                                <p className='text-white text-[18px]'>{items.title}</p>
                            </div>
                        )
                    })
                }
                <div>

                </div>
            </div>
        </div>
    )
}
export default CounterUp
