import React from "react";


const Form = (props) => {

  return (
    <div className="py-10 px-2">
      <div class="elfsight-app-6bda03a5-8a9a-4c81-90be-3d8692755d76"></div>
    </div >
  );
};

export default Form;


// variable del formulario:
// tname=""
// tlname=""
// temail=""
// tphone=""
// taddress=""
// ttypeservice=""
// tbestway=""
// tcheckphone=""
// tcheckcorreo=""
// tmessage=""
// tbutton=""
// isenglish="true"
